import { IAction } from 'actions/commonAction';
import { GET_FINCH_PROVIDERS_ACTION } from 'actions/finch/getFinchProviders';
import { Provider2 as FinchProvider } from 'api/generated/models';

type IFinch = {
    providers?: FinchProvider[];
};

const initialState: IFinch = {
    providers: [],
};

export const finch = (state = initialState, action: IAction<FinchProvider[]>): IFinch => {
    if (action.type === GET_FINCH_PROVIDERS_ACTION.success) {
        return { ...state, providers: action.data };
    }
    return state;
};
